<template>
  <div>
    <div class="banner">
      <warning-tip code="SPTSSXCX"></warning-tip>

      <main-top
        title="属性查询"
        desc="在线查询天猫、淘宝商品宝贝属性。"
        spend="SPTSSXCX"
      >
         <!-- 使用搜索框组件 -->
         <InputView
            :extractProductId="true"
            :CanYouClick="CanYouClick"
            placeholder-name="请输入您的宝贝链接或宝贝ID"
            @sendSearchName="getSearchName"
          ></InputView>
      </main-top>

      <bordure-row title="宝贝属性" shadow style="margin-bottom: 30px">
        <div class="bannerContent" style="border: 1px solid #e5e5e5">
          <div
            class="bannerContentIn"
            v-for="(item, index) in dataArr"
            :key="index"
          >
            <p class="title">{{ item.name }}:</p>
            <p class="content">
              {{ item.content }}
            </p>
          </div>
          <div v-if="dataArr.length == 0" class="notData">暂无数据</div>
        </div>
      </bordure-row>

      <!-- 讲解文案区域 -->
      <Introduce :introduce="introduce"></Introduce>
    </div>
  </div>
</template>

<script>
import { getGoodsCateGoryInfo } from "../../request/api"; //这里是引入请求
import InputView from "../public/InputView";
import Introduce from "../public/Introduce";
export default {
  data() {
    return {
      input: "",
      CanYouClick: true,
      dataArr: [
        {
          content: "品牌1",
          name: "品牌",
        },
        {
          content: "系列1、系列2",
          name: "系列",
        },
        {
          content: "材质1、材质2",
          name: "材质",
        },
        {
          content: "颜色1、颜色2、颜色3",
          name: "颜色分类",
        },
        {
          content: "规格1、规格2、规格3",
          name: "规格",
        },
        {
          content: "2014年夏季",
          name: "上市时间",
        },
        {
          content: "123456789",
          name: "货号",
        },
        {
          content: "分类1、分类2、分类3",
          name: "分类",
        },
        {
          content: "是",
          name: "是否商场同款",
        },
        {
          content: "商场同款(线上线下都销售)",
          name: "销售渠道类型",
        },
      ],
      introduce: [
        {
          title: "功能介绍",
          icon: "&#xe60a;",
          content: [
            "方便商家快速提取商品属性",
            "准确的属性，不会被淘宝处罚，可以提高宝贝的流量。",
          ],
        },
      ], //介绍相关数组
    };
  },
  components: {
    InputView,
    Introduce,
  },
  methods: {
    //   子传父的方法
    getSearchName(value) {
      this.$store.commit("alterLoadContent", "正在查询该类目属性的相关数据");
      this.dataArr = [];
      this.CanYouClick = false; //不允许再点击搜索
      getGoodsCateGoryInfo({
        itemid: value,
      })
        .then((data) => {
          var newData = [];
          try {
            //新接口的解析方法
            var newArr = JSON.parse(data.data.goodsEntity.propsName);
            newArr.forEach((element) => {
              //遍历数组
              for (var key in element) {
                newData.push({
                  name: key,
                  content: element[key],
                });
              }
            });
          } catch (e) {
            //默认解析方法
            var newArr = data.data.goodsEntity.propsName.split(";"); //首先将数据通过；转换成数组
            var newData = [];
            newArr.forEach((element) => {
              //遍历数组
              newData.push({
                //将数组内的字符串转换成对象类型
                name: this.getCaption(element, 0), //截取：前面的内容
                content: this.getCaption(element, 1), //截取：后面的内容
              });
            });
          }

          this.dataArr = newData; //赋值
        })
        .finally(() => {
          this.$store.commit("alterJumpFlag", false);
          this.CanYouClick = true; //允许再次搜索
        });
    },
    // 截取字符串：的内容state=0截取:前面，state=1截取:后面
    getCaption(obj, state) {
      var index = obj.lastIndexOf(":");
      if (state == 0) {
        obj = obj.substring(0, index);
      } else {
        obj = obj.substring(index + 1, obj.length);
      }
      return obj;
    },
  },
};
</script>

<style lang="less" scoped>
.bannerContent {
  display: flex;
  flex-wrap: wrap;

  .bannerContentIn {
    width: 50%;
    display: flex;
    line-height: 40px;

    .title,
    .content {
      font-size: 12px;
      text-align: left;
      line-height: 40px;
      border: 1px solid #e5e5e5;
      padding-left: 20px;
    }

    .title {
      width: 25%;
      color: #111111;
      background-color: #f8f8f8;
    }

    .content {
      width: 100%;
      padding: 0 20px;
      color: #333333;
      letter-spacing: 0;
      box-sizing: border-box;
    }
  }
}
</style>